<template>
    <div id="firmaekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-1"
                            label="Toplama Noktası Adı: (zorunlu)"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="form.firma_unvan"
                            required
                            type="text"
                            placeholder="Toplama noktası için bir ad girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            label="Tel:"
                            label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.firma_tel"
                            required
                            type="number"
                            placeholder="Firma telefonunu girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-3"
                            label="E-Posta:"
                            label-for="input-3">
                            <b-form-input
                            id="input-3"
                            v-model="form.firma_eposta"
                            type="email"
                            placeholder="Firma e-posta adresini girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Adres: (zorunlu)"
                            label-for="input-4">
                            <b-form-input
                            id="input-4"
                            v-model="form.firma_adres"
                            placeholder="Firma açık adresini girin."
                            ></b-form-input>
                        </b-form-group>
                        <b-row>
                            <!-- <b-col cols="12" sm="4">
                                <b-form-group label="İl Seçin:">
                                    <b-form-select v-model="form.firma_il" required :options="il_form"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="4">
                                <b-form-group label="İlçe Seçin:">
                                    <b-form-select v-model="form.firma_ilce" required :options="ilce_form"></b-form-select>
                                </b-form-group>
                            </b-col> -->
                            <b-col cols="12" sm="4">
                                <b-form-group label="Hangi sıklıkla gidilecek:">
                                    <b-form-select v-model="form.firma_sure" :options="options"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Toplayıcı/Yetkili">
                                    <b-form-select v-model="form.firma_user" :options="users"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button block type="submit" variant="primary" class="float-right mb-5">Kaydet</b-button>
                    </b-form>
                </b-col>    

                <b-col sm="12">
                    <yandex-map 
                    :settings="settings" 
                    :coords="coords" 
                    :zoom="16" 
                    @click="harita_tiklandi"
                    class="mb-5"
                    style="width: 100%; height: 400px;">
                        <ymap-marker :coords="coords" marker-id="12"></ymap-marker>
                    </yandex-map>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import Swal from 'sweetalert2';


export default {
    data(){
        return{
            settings : {
                lang: 'tr_TR',
            },
            coords : [],
            //form : [],
            
        }
    },


    components: { yandexMap, ymapMarker },


    computed : {
        en_boy(){
            return this.$store.state.konum;
        },
        iller(){
            return this.$store.state.iller;
        },
        ilceler(){
            return this.$store.state.ilceler;
        },
        il_form(){
            return this.iller.map(r => {
                return {
                    text : r.name,
                    value : r.id,
                }
            })
        },
        ilce_form(){
            const ilceler = this.ilceler.filter(r => r.il_id == this.form.firma_il);
            return ilceler.map(r => {
                return {
                    text : r.name,
                    value : r.id
                }
            })
        },
        firma(){
            return this.$store.state.firma;
        },
        options(){
            var deger = [];
            for(var x=0; x<100; x++){
                deger.push({
                    value: x,
                    text: x + " günde bir"
                })
            }
            return deger;
        },
        users(){
            return this.$store.state.users.reduce((item, c) => {
                if(c.user_rutbe == 3){
                    item.push({
                        value: c.user_id,
                        text: c.user_adsoyad
                    });
                }
                return item;
            }, []);
        },
        form(){
            if(this.$route.params.firma_id){
                return this.$store.state.firmalar.filter(c => c.firma_id == this.$route.params.firma_id)[0];
            }else{
                return [];
            }
        }
    },

    methods : {
        harita_tiklandi(e){
            this.coords = e.get('coords');
            //this.coords.push(e.get('coords'));
            console.log(this.coords)
        },
        ekle(){
            var popup = Swal.fire({
                title: 'Lütfen Bekleyin',
                text: 'İşleminiz yapılırken lütfen bekleyin.',
                icon: 'info',
                showConfirmButton: false,
            });

            
            if(this.$route.params.firma_id){
                this.$store.dispatch("firmaDuzenle", {
                    firma_konum : this.form.firma_konum[0] + "," + this.form.firma_konum[1],
                    firma_unvan : this.form.firma_unvan,
                    firma_tel : this.form.firma_tel,
                    firma_eposta : this.form.firma_eposta,
                    firma_adres : this.form.firma_adres,
                    firma_il : this.form.firma_il,
                    firma_ilce : this.form.firma_ilce,
                    firma_sure: this.form.firma_sure,
                    firma_user: this.form.firma_user,
                    firma_id : this.$route.params.firma_id,
                }).then(r => {
                    popup.close();
                    if(r){
                        this.$router.push("/firmalar")
                    }
                })
            }else{
                this.$store.dispatch("firmaEkle", {
                    firma_konum : this.form.firma_konum[0] + "," + this.form.firma_konum[1],
                    firma_unvan : this.form.firma_unvan,
                    firma_tel : this.form.firma_tel,
                    firma_eposta : this.form.firma_eposta,
                    firma_adres : this.form.firma_adres,
                    firma_il : this.form.firma_il,
                    firma_ilce : this.form.firma_ilce,
                    firma_user: this.form.firma_sure,
                    firma_suer: this.form.firma_user,
                }).then(r => {
                    popup.close();
                    if(r){
                        this.$router.push("/firmalar")
                    }
                })
            }
        },
    },

    mounted(){
        this.coords = this.en_boy;
    },

    watch : {
        coords(a){
            this.form.firma_konum = a;
        },
        firma(a){
            this.form = a ? a : [];
            var konum = a.firma_konum.split(",")
            this.coords = [+konum[0], +konum[1]];
        }
    }
}
</script>